import React from "react";

import { User } from "./User"

export interface AuthContextInterface {
    user: User | null | undefined;
}

export const AuthContext = React.createContext<AuthContextInterface>({
    user: {} as User
})

export const withAuth = (Component: any) => {
    return (props: any) => {
        return (
            <AuthContext.Consumer>
                { ctx => <Component {...props} user={ctx.user} /> }
            </AuthContext.Consumer>
        )
    }
};
