import { COLOR_FADE_DELAY, COLOR_FADE_DURATION } from "./Constants";

// calls a setter function, then calls setTimeout with timeout to call the setter with undefined
export const setForDuration = <T,>(timeout: number, setter: (s: T | undefined) => void, value: T | undefined) => {
    setter(value);
    setTimeout(() => {
        setter(undefined);
    }, timeout);
};

// calls a setter function, then calls setTimeout with timeout to call the setter with undefined
export const setForFadeDuration = <T,>(setter: (s: T | undefined) => void, value: T | undefined) => setForDuration(COLOR_FADE_DELAY + COLOR_FADE_DURATION, setter, value);
